@import 'theme/variable';
$primary: var(--primary);
$secondary: var(--secondary);
$light: var(--light);
$dark: var(--dark);
@import 'theme/style';
@import 'theme/responsive';
@import 'theme/keyframes';
@import 'theme/customize';
@import 'theme/theme-dark';
@import 'theme/theme-rtl';
@import 'theme/nav';
@import 'flaticon';



